@import '~ngx-toastr/toastr';
@import '~bootstrap/scss/bootstrap-grid';
@import '_theme.scss';
$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
@import '~material-design-icons-iconfont/src/material-design-icons';
.container {
    all: revert;
}

.a {
    all: revert;
}

.mat-danger {
    background-color: red;
    color: white;
}

.dark-theme .mat-danger {
    background-color: red;
    color: white;
}

.mat-info {
    background-color: #00b0ff;
    color: white;
}

.dark-theme .mat-info {
    background-color: #00b0ff;
    color: white;
}

body {
    margin: 0;
}

.flex-stretch {
    flex: 1 0 auto;
}

.mr-8 {
    margin-right: 8px;
}

::-webkit-scrollbar {
    width: 4px;
    overflow-y: scroll;
    background: white;
    box-shadow: inset 0 0 0px gainsboro;
}

::-webkit-scrollbar-thumb {
    background: gainsboro;
    border-radius: 10px;
}

.dark-theme ::-webkit-scrollbar {
    background: #424242;
}

.dark-theme ::-webkit-scrollbar-thumb {
    background: rgb(43, 43, 43);
}

.dark-theme .mat-drawer.mat-drawer-push {
    background-color: #212121!important;
}

.dark-theme .mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgb(0 0 0 / 82%);
}

.dark-theme .loader {
    background-color: #3e3e3e00!important;
    opacity: .5!important;
}

table {
    width: 100%;
}

.mat-flat-button {
    margin: 8px 8px 8px 0!important;
    border-radius: 0px!important;
}

.mat-raised-button {
    margin: 8px 8px 8px 0!important;
}

.toast-container .ngx-toastr {
    box-shadow: none;
}

.toast-container .ngx-toastr:hover {
    box-shadow: none;
}

.mat-form-field {
    width: 100%;
}

.center-screen {
    position: fixed;
    top: 30%;
    left: 50%;
    width: 30em;
    margin-top: -9em;
    margin-left: -15em;
}

.mat-row:hover {
    background-color: whitesmoke;
}

.dark-theme .mat-row:hover {
    background-color: rgb(58, 58, 58);
}

.mat-dialog-container {
    box-shadow: 0 10px 60px 0 rgba(29, 29, 31, .09)!important;
}

@media screen and (max-width:1920px) {
  .mat-dialog-container {
    min-width: 50vw!important;
  }
}

.left {
    float: left;
}

.right {
    float: right;
}

.mat-card {
    border-radius: 4px!important;
    box-shadow: 0 10px 60px 0 rgba(29, 29, 31, .09)!important;
}

.module-card {
    padding-top: 32px!important;
}

.mat-list-item {
    font-size: 14px!important;
}

.mat-container {
    height: 100%!important;
}

.mat-menu-content {
    padding: 0px!important;
}

.mat-menu-panel {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 0%), 0px 5px 8px 0px rgb(0 0 0 / 0%), 0px 1px 14px 0px rgb(0 0 0 / 8%)!important;
}

.mat-divider {
    opacity: 50%;
}

.mat-card-row-column {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding-right: 15px;
}

@media (max-width: 850px) {
    .mat-card-row-column {
        width: 100%;
    }
}

.mat-card {
    margin-bottom: 20px;
}

.mat-card-content {
    padding: 16px!important;
}

.home-center-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}

.u-ml-auto {
    margin-left: auto !important
}

.u-mr-auto {
    margin-right: auto !important
}

.c-card {
    margin-bottom: 30px;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 10px 60px 0 rgba(29, 29, 31, .09)
}

.dark-theme .c-card {
    background: #424242;
    color: white;
    box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0)
}

.c-card.is-animated {
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s
}

.c-card.is-animated:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}

.c-card--center {
    text-align: center
}

.c-card__title {
    margin-bottom: 10px
}

.position-fixed {
    position: fixed
}

.cdk-global-overlay-wrapper {
  overflow: auto;
  pointer-events: auto;  // needed for chrome but prevents backdrop click close
  max-height: 90%;
  margin: 20px;
}

.mat-dialog-container-no-padding {
  mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}


// Mat Table Style
table {
  width: 100%;
}

.text-right {
  text-align: right !important;
}
td.mat-cell{
  border-bottom-style:dotted;
  height: 60px!important;
}

th.mat-header-cell{
  border-bottom-style: none;

}
.mat-icon {
  width: 17px;
  height: 17px;
  font-size: 17px;
}

.mat-raised-button
{
  min-width: 0px!important;
}
